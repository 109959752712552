<template>
  <div
      class="flex-row-fluid d-flex flex-column position-relative p-14 overflow-hidden"
  >
    <div class="d-flex flex-column-fluid flex-center">
      <div class="d-flex flex-column align-items-center align-center text-center ">

        <img v-if="email_verification.status != 'ERROR'" src="media/misc/verified.png" style="height: 200px"/>
        <img v-else src="media/misc/cancelled.png" style="height: 200px"/>
        <h1 v-if="email_verification.status != 'ERROR'" class="mt-2 mb-5">Thank for your registration.</h1>
        <h3 class="mt-2 mb-5" :class="email_verification.status == 'ERROR'?'text-danger':'text-success'"
            v-text="email_verification.msg"></h3>
        <!--        <h5 class="mb-5 ">Please keep this number to make any further inquiry. </h5>-->
        <a href="#" @click.prevent="$router.push({name:'pre-registration'})"
           class="text-black font-size-base badge badge-primary">
          New Registration
        </a> <a href="#" @click.prevent="$router.push({name:'index'})" class="text-black font-size-base">
        Visit Website
      </a>
      </div>
    </div>
    <v-overlay :value="isBusy">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import StepperMixin from "@/mixins/StepperMixin";

export default {
  name: "Success",
  mixins: [StepperMixin],
  computed: {
    token1() {
      return this.$route.params.token1
    },
    token2() {
      return this.$route.params.token2
    },
  },
  mounted() {
    if (this.token1 && this.token2) {
      this.verifyEmail(this.token1, this.token2)
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
